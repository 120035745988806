import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Canvas, extend, useFrame, useLoader, useThree } from '@react-three/fiber';
import circleImg from './Assets/icons/circle.png';
import { Suspense, useCallback, useMemo, useRef } from 'react';
import './Styles/animation.css'
import './Styles/home.sass'

extend({ OrbitControls })

let t = 0;
let f = 0.003;
let a = 6;

function CameraControls() {
  const {
    camera,
    gl: { domElement }
  } = useThree();

  const controlsRef = useRef();
  useFrame(() => controlsRef.current.update())

  return (
    <orbitControls
      ref={controlsRef}
      args={[camera, domElement]}
      autoRotate
      autoRotateSpeed={0.4}
    />
  );
}

const openAbout = () => {
  document.querySelector('.home__screen').style.opacity = 0
  document.querySelector('.home__screen').style.pointerEvents = 'none'
  const open = setInterval(function () {
    if (a <= 100) {
      a = a + 2
    }

    if (f >= 0.0003) {
      f = f / 1.01
    } else {
      document.querySelector('.about__text').style.opacity = 1
      clearInterval(open)
      closeAbout()
    }
  }, 1000 / 60)
}

const closeAbout = () => {
  setTimeout(() => {
    document.querySelector('.home__screen').style.opacity = 1
    document.querySelector('.home__screen').style.pointerEvents = 'all'
    document.querySelector('.about__text').style.opacity = 0
    const close = setInterval(function () {
      if (a >= 6) {
        a = a - 1
      }

      if (f <= 0.003) {
        f = f * 1.004
      } else {
        console.log('closed');
        clearInterval(close)
      }
    }, 1000 / 60)
  }, 10000);
}


function Points() {
  const imgTex = useLoader(THREE.TextureLoader, circleImg);
  const bufferRef = useRef();

  const graph = useCallback((x, z) => {
    return Math.sin(f * (x ** 2 + z ** 2 + t)) * a;
  }, [t, f, a])

  const count = 100
  const sep = 3
  let positions = useMemo(() => {
    let positions = []

    for (let xi = 0; xi < count; xi++) {
      for (let zi = 0; zi < count; zi++) {
        let x = sep * (xi - count / 2);
        let z = sep * (zi - count / 2);
        let y = graph(x, z);
        positions.push(x, y, z);
      }
    }

    return new Float32Array(positions);
  }, [count, sep, graph])

  useFrame(() => {
    t += 3

    const positions = bufferRef.current.array;

    let i = 0;
    for (let xi = 0; xi < count; xi++) {
      for (let zi = 0; zi < count; zi++) {
        let x = sep * (xi - count / 2);
        let z = sep * (zi - count / 2);

        positions[i + 1] = graph(x, z);
        i += 3;
      }
    }

    bufferRef.current.needsUpdate = true;
  })

  return (
    <points>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          ref={bufferRef}
          attachObject={['attributes', 'position']}
          array={positions}
          count={positions.length / 3}
          itemSize={3}
        />
      </bufferGeometry>

      <pointsMaterial
        attach="material"
        map={imgTex}
        color={"rgb(0, 200, 255)"}
        size={0.01}
        sizeAttenuation
        transparent={false}
        alphaTest={0.5}
        opacity={1.0}
      />
    </points>
  );
}

function AnimationCanvas() {
  return (
    <Canvas
      camera={{ position: [0, 90, 0], fov: 80 }}
    >
      <Suspense fallback={null}>
        <Points />
      </Suspense>
      <CameraControls />
    </Canvas>
  );
}


function App() {
  return (
    <div>
      <div className="anim">
        <Suspense fallback={<div>Loading...</div>}>
          <AnimationCanvas />
        </Suspense>
      </div>

      <div className="home__screen">
        <div className="my__name">
          <h2>Kangarli</h2>
          <h1>Rza</h1>
          <div className="devdes">
            <h3>developer</h3>
            <h3>|</h3>
            <h3>designer</h3>
          </div>
        </div>
        <div className="home__menu">
          <h1 onClick={openAbout}>about</h1>
          {/* <h1>blog</h1>
          <h1 onClick={() => { window.scrollTo(0, document.documentElement.clientHeight) }}>portfolio</h1> */}
        </div>
      </div>

      <div className="about__text">
        <p>
          Hello, my name is Rza. I am from Azerbaijan, I was born on 25 December, 1999. I am a graduated of Azerbaijan Technical University and on time i studying master degree. My specialty is an Electrical engineer, the interest range for the technology is not limited only with electrical development, in addition, my common aim is to achieve Computer innovations, Programming and sometimes design science as well. I have got the skills to use HTML, CSS, SASS, JavaScript, Python for Web and Mobile App Developing.
        </p>
      </div>
    </div>
  );
}

export default App;